<template>
  <div
    id="landingContainer"
    :class="{ 'bg-dark-grey rfs-p-8': !breakpoints.greaterThanMd }"
  >
    <div
      id="landingControls"
      :class="{ 'side-bar left': breakpoints.greaterThanMd }"
    >
      <h1 class="rfs-m-4-b">Compare</h1>
      <div>
        <router-view />
        <button
          class="btn btn-primary logout-button"
          data-logout-btn
          v-if="isLoggedIn"
          @click="logout"
        >
          Return to Trisus Home
        </button>
        <loader id="logoutLoader" v-if="logoutLoader" />
      </div>
      <div id="landingTerms" class="rfs-m-8-t">
        <a href="/terms-of-service" class="footer-links">Terms of Service</a> |
        <a href="/privacy-policy" class="footer-links">Privacy Policy</a>
        <p class="fs-12 fw-r">
          ©2022 The Craneware Group. Contains trade secrets and confidential and proprietary
          information.<br />All rights reserved. No unauthorized reproduction.
        </p>
      </div>
    </div>
    <div v-if="breakpoints.greaterThanMd" id="theHighRes">
      <video
        alt="Program codes and a busy street - Royalty-free Animation - Moving Image Stock Footage Video"
        autoplay
        muted
        loop
        src="../../assets/video/iStock-468619656.mp4"
        style="
          position: absolute;
          bottom: 0;
          right: ;
          min-width: 100%;
          min-height: 100%;
          z-index: 0;
          max-width: none;
        "
      ></video>
      <div id="blubBackground">
        <div id="blurb" class="color-secondary">
          <h3 class="rfs-m-6-b fw-500" style="font-size: 3em">Trisus Medication Compare</h3>
          <p style="padding-top: 35px">
            <span class="rfs-24">Actionable Real World Data</span><br /><span
              class="rfs-42"
              >Improve Quality</span
            ><font-awesome-icon class="rfs-30" icon="arrow-up" />
            <span style="padding-left: 12px"></span>
            <span class="rfs-42" style="white-space: nowrap"
              >Reduce Total Cost of Care</span
            >
            <font-awesome-icon class="rfs-30" icon="arrow-down" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import selectStore from '../../store/modules/global/filters/select';
  import useLogout from '@/composables/useLogout.js';

  export default {
    name: 'Landing',
    setup(_, context) {
      const logout = () => useLogout(context);
      return {
        logout
      };
    },
    computed: {
      logoutLoader() {
        return this.$store.getters['logOutLoaderStore' + '/getValue'];
      }
    },
    mounted() {
      this.magicModule('logOutLoaderStore', selectStore);
    }
  };
</script>

<style lang="scss">
  @use '@/assets/scss/responsive';
  @use './node_modules/include-media/dist/include-media.scss' as im with
    ($breakpoints: responsive.$breakpoints);
  @use '@/assets/scss/vars.scss' as v;

  #landingContainer {
    margin: auto;
    height: 100vh;
  }

  #landingControls {
    padding: v.$sidebarPadding;
    width: 760px;
    h2 {
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: 0.0125em;
      margin-bottom: 0.5rem;
    }
  }

  #hospitalSelector {
    .vs__selected {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      width: 270px;
    }
    ul {
      display: table-cell;
      width: auto;
    }
  }

  // responsive styles

  @include im.media('>=md') {
    #landingControls {
      min-width: 320px;
      max-width: 760px;
      vertical-align: top;
      position: relative;
      z-index: 1;
      display: table-cell;
      // overflow: hidden;
    }

    #theHighRes {
      width: 100%;
      max-width: 100%;
      position: relative;
      overflow: hidden;
      display: table-cell;
      height: 100vh;
      text-align: center;

      video {
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: 0;
        max-width: none;
      }
    }
    #blubBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(36, 41, 46, 0.8);
    }
    #blurb {
      position: absolute;
      z-index: 2;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      filter: drop-shadow(black 2px 2px 4px); //must use filter for svg icons
      padding: 36px;
      width: 100%;
    }
  }

  @include im.media('>=lg') {
    #landingControls {
      min-width: 350px;
      max-width: 760px;
    }
  }

  @include im.media('>=xl') {
    #landingControls {
      min-width: 375px;
      max-width: 760px;
      position: relative;
    }
  }
</style>
