export default function (context) {
  context.root.$store.commit('logOutLoaderStore' + '/setValue', true);

  context.root.$store.dispatch('auth/logout', 'manual').then(() => {
    context.root.$store.commit('auth/clearUsername');
    context.root.$store.commit('auth/clearReturnToPath');
    context.root.$store.commit('logOutLoaderStore' + '/setValue', false);

    // Clear the other stores
    // Any store registered can define a "clearOnLogout" mutation, will be called here
    let allStoreModules = context.root.$store._modules.root._children;
    for (let i in allStoreModules) {
      if (allStoreModules[i]._rawModule.mutations.clearOnLogout) {
        context.root.$store.commit(i + '/clearOnLogout');
      }
    }

    context.root.$router.push({ name: 'Login' });
    location.reload();
  });
}
